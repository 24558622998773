import Template from "../lib/template";
import PageRenderer from "../components/page";
import Page from "../lib/page";
import {useRouter} from "next/router";
import PageSpinner from "../components/spinner/page";
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Footer from "../components/footer";
import React from "react";
import Header from "../components/header";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Home(props) {
    const router = useRouter();

    if (router.isFallback) {
        return <PageSpinner />
    }

    return (
        <>
            {/* Header */}
            <Header />

            {/* Content */}
            {props.template.type === 'block'
                ?
                <PageRenderer
                    type={props.template.type}
                    date={props.template.date}
                    data={props.template.data}
                    embed={false}
                />
                :
                <div className={'col-12 col-md-11 col-lg-10 col-xl-9 col-xxl-8 mt-3 mb-5 ps-3 pe-3 ps-md-5 pe-md-5 ms-lg-5 me-lg-5'}>
                    <PageRenderer
                        type={props.template.type}
                        date={props.template.date}
                        data={props.template.data}
                        embed={false}
                    />
                </div>
            }

            {/* Footer */}
            <Footer />
        </>
    )
}

/**
 *
 * @param context
 * @returns {Promise<{props: {}}>}
 */
export async function getStaticProps(context) {
    let page = new Page;
    page.init(context.locale);

    let slug = context.params.slug.join('/');

    let template = new Template;
    await template.init(
        page.language,
        slug
    );

    if (!template.found) {
        return {
            notFound: true,
        };
    }

    await template.enrichData(context.locale);

    return {
        props: {
            ...(await serverSideTranslations(context.locale, ['common', 'languages', 'countries',])),
            "template": {
                "type": template.type,
                "date": template.date,
                "data": template.data
            }
        }
    }
}

/**
 *
 * @returns {Promise<{or: boolean, paths: [{params: {}}], fallback: boolean}>}
 */
export async function getStaticPaths() {
    return {
        paths: [],
        fallback: true
    };
}